@import "../../scss/main";

.Button {
  display: inline-block;
  padding: 0.6rem 1.25rem;
  background-color: $PurpleColor;
  color: $WhiteColor;
  border-radius: 1.625rem;
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
  transition: all 0.2s ease;
  border: 2px solid $PurpleColor;

  &:hover {
    background-color: $WhiteColor;
    color: $PurpleColor;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $PurpleColor;
    opacity: 0.7;
    color: $WhiteColor;

    &:hover {
      background-color: $PurpleColor;
      opacity: 0.7;
      color: $WhiteColor;
    }
  }
}

.Button_outline {
  background-color: $WhiteColor;
  color: $PurpleColor;


}
