@import "../../scss/main";

.footer {
  width: 767px;
  max-width: 90vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-family: "Helvetica";
  color: black;
  padding: 3.75rem 0;
  gap: 1rem;
  flex-wrap: wrap;
  flex-grow: 1;

  @include md {
    padding: 1.5rem 0;
  }

  a {
    color: $BlackColor;
  }

  .footer_link {
    cursor: pointer;
    text-decoration: none;
  }
}
