@import "../../scss/main";

.sessionRatingModal {
  width: 400px;
  max-width: calc(100vw - 20px);
  max-height: calc(100vh - 2rem);
  background-color: $WhiteColor;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  .sessionRatingModal_top {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    img {
      height: 1.5rem;
      cursor: pointer;
    }
  }

  h3 {
    font-family: "Helvetica";
    text-align: center;
    color: $BlackLight;
    line-height: 1.4;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  h5 {
    width: 100%;
    font-family: "Helvetica";
    text-align: left;
    color: $BlackLight;
    line-height: 1.4;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }

  .sessionRatingModal_bubbles {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: flex-start;
    margin-bottom: 1rem;

    span {
      display: inline-block;
      padding: 0.3rem 0.8rem;
      border-radius: 1rem;
      background-color: $Grey1;
      color: $BlackLight;
      font-family: "Helvetica";
      line-height: 1;
      border: 1px solid $Grey2;
      cursor: pointer;
    }

    .selected {
      background-color: $PurpleColor;
      color: $WhiteColor;
      border: 1px solid $PurpleColor;
    }
  }

  button {
    width: 100%;
    margin-bottom: 1rem;
  }

  textarea {
    resize: none;
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-family: "Helvetica";
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    min-height: 3rem;
  }
}
