@import "../../scss/main";

.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border: 2px solid #ddd;
  border-radius: 5px;
  background: $WhiteColor;
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 2px solid #ddd;
  background: #eee;
  color: #666;
}

.msger-header-end {
  font-size: 1rem;
  font-weight: 600;
  font-family: "Helvetica";
  color: $PurpleColor;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}
.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.msg:last-of-type {
  margin: 0;
}
.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.msg-tip {
  justify-content: center;

  & > div {
    background-color: $YellowColor;
    border-radius: 1rem;
    padding: 0.25rem 1rem;
  }
}
.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: #ececec;

  img {
    max-width: 100%;
    max-height: calc(100vh - 250px);
    min-height: 4rem;
  }
}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  background: #579ffb;
  color: #fff;
  border-bottom-right-radius: 0;
}
.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: 2px solid #ddd;
  background: #eee;
  align-items: center;

  label {
    cursor: pointer;
  }
}
.msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}
.msger-input {
  flex: 1;
  background: #ddd;
  resize: none;
  font-family: "Helvetica";
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  height: 3rem;
}
.msger-send-btn {
  margin-left: 10px;
  background: $PurpleColor;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
.msger-send-btn:hover {
  opacity: 0.7;
}

.msger-chat {
  // background-color: #fcfcfe;
}

.chat_imageContainer {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading {
    padding: 0;
  }

  & > img {
    height: 8rem;
    max-width: 100%;
  }
}
