@import "../../scss/main";

.privacyPolicy {
  width: 845px;
  max-width: 90vw;
  margin: 0 auto;

  h3 {
    font-size: 2.75rem;
    font-family: "Helvetica-Bold";
    margin-top: 4.5rem;
    margin-bottom: 5rem;
    text-align: center;

    @include md {
     margin-bottom: 3rem;
     margin-top: 2.5rem;
    }
  }

  p {
    font-family: "Helvetica";
    font-size: 1.25rem;
    letter-spacing: 0.1px;

    @include md {
     margin-bottom: 2rem;
    }
  }
}
