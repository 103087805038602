@import "../../scss/main";

.advisorDash {
  width: 1200px;
  max-width: 100vw;
  margin: auto;
  font-family: "Helvetica";
  height: calc(100vh - 90px);
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: $BlackLight;
  display: flex;

  .advisorDash_leftBar {
    width: 400px;
    min-width: 400px;
    border-right: 1px solid;
    height: 100%;
    display: grid;
    grid-template-rows: 1.6fr 1fr;
    border-color: $BlackLight;
  }

  .advisorDash_posts {
    border-bottom: 1px solid;
    border-color: $BlackLight;
    padding: 1rem;
    overflow: auto;

    & > h3 {
      font-size: 1.25rem;
      border-bottom: 1px solid;
      color: $PurpleColor;
    }
  }

  .advisorDash_postsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;
  }

  .advisorDash_sessions {
    padding: 1rem;
    overflow: auto;

    & > h3 {
      font-size: 1.25rem;
      border-bottom: 1px solid;
      color: $PurpleColor;
      margin-bottom: 0.5rem;
    }
  }

  .advisorDash_session {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    cursor: pointer;

    img {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
    }
  }

  .advisorDash_sessionActive {
    background-color: #8d87ff70;
  }

  .advisorDash_sessionSub {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.2;
      margin: 0;
    }

    p {
      font-size: 14px;
      color: $Grey2;
    }
  }
}
