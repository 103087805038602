@import "../../scss/main";

.advisorPostCard {
  background-color: $Grey1;
  padding: 10px;
  border-radius: 20px;

  .advisorPostCard_header {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    img {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
    }

    h3 {
      font-size: 1.125rem;
      margin: 0;
      line-height: 1;
    }

    span {
      color: $Grey2;
      font-size: 14px;
      line-height: 1.1;
    }
  }

  .advisorPostCard_time {
    width: 100%;
    text-align: right;
    color: $Grey2;
    font-size: 14px;
    margin: 2px 0;
  }

  .advisorPostCard_queryMutedText {
    color: $Grey2;
    font-size: 14px;
  }

  .advisorPostCard_inquiry {
    margin: 0;
    line-height: 1.2;
  }

  .advisorPostCard_expander {
    color: $PurpleColor;
    font-size: 14px;
    cursor: pointer;
    margin: 0 2px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    img {
      height: 12px;
    }
  }

  .advisorPostCard_query {
    border-bottom: 1px solid $Grey2;
    padding-bottom: 1rem;
  }

  .advisorPostCard_queryDetails {
    margin: 0.5rem 0;

    h3 {
      font-size: 18px;
      margin: 0;
      line-height: 1.2;
      margin-bottom: 1rem;
    }
  }

  .advisorPostCard_queryDetail {
    margin-bottom: 0.75rem;
    p {
      font-size: 14px;
      color: $Grey2;
      line-height: 1.2;
      margin: 0;
    }

    h4 {
      font-size: 16px;
      margin: 0;
      line-height: 1.2;
    }
  }

  .advisorPostCard_offer {
   margin: 0.5rem 0;
   margin-bottom: 1rem;
   font-size: 14px;
   color: $Grey2;

   p {
    font-size: 18px;
    color: $BlackLight;
    font-weight: 600;
line-height: 1.2;
   }

  }

  button {
    width: 100%;
    &:hover {
      background-color: transparent;
    }
  }
}
