@import "./main";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Lulo-clean", sans-serif;
  line-height: 1.6;
}
button {
  border: none;
  outline: none;
  cursor: pointer;
}

@font-face {
  font-family: "Lulo-clean";
  src: url("../assets/fonts/FontsFree-Net-Lulo-Clean-W01-One-Bold.ttf")
    format("truetype");
  font-style: oblique; /* Specify the font style, e.g., normal or italic */
}

@font-face {
  font-family: "Helvetica";
  src: url("../assets/fonts/Helvetica-Light.ttf") format("truetype");
  font-weight: 300; /* Specify the font weight */
  font-style: oblique; /* Specify the font style, e.g., normal or italic */
}

@font-face {
  font-family: "Helvetica";
  src: url("../assets/fonts/Helvetica-Bold.TTF") format("truetype");
  font-weight: 700; /* Specify the font weight */
  font-style: oblique; /* Specify the font style, e.g., normal or italic */
}

@font-face {
  font-family: "Helvetica";
  src: url("../assets/fonts/Helvetica.ttf") format("truetype");
  font-weight: 400; /* Specify the font weight */
  font-style: oblique; /* Specify the font style, e.g., normal or italic */
}

//general classes

.error {
  color: $ErrorColor;
  font-size: 14px;
  font-family: "Helvetica";
}
