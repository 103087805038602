@import "../../scss/main";

.advisorLoginModal {
  width: 600px;
  max-width: calc(100vw - 20px);
  border-radius: 1rem;
  background-color: $WhiteColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  padding-bottom: 3rem;

  h3 {
    font-size: 2rem;
    color: $PurpleColor;
    line-height: 1.5;
    margin-bottom: 1rem;
    font-family: "Helvetica";

    @include md {
      font-size: 1.25rem;
    }
  }

  .advisorLoginModal_xmark {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    img {
      height: 1.5rem;
      width: 1.5rem;
      cursor: pointer;
    }
  }

  .advisorForm_passwordInput {
    position: relative;

    img {
      position: absolute;
      top: 36px;
      right: 1rem;
      height: 1rem;
      cursor: pointer;
    }
  }

  form {
    width: 400px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    & > div {
      width: 100%;
    }
  }
}
