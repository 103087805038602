@import "../../scss/main";

.thanksModal {
  width: 400px;
  max-width: calc(100vw - 20px);
  background-color: $WhiteColor;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .thanksModal_top {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 1rem;

    img {
      height: 1.5rem;
      cursor: pointer;
    }
  }

  & > img {
    height: 4rem;
    margin-bottom: 4rem;
  }

  h3 {
    font-family: "Helvetica";
    text-align: center;
    color: $BlackLight;
    line-height: 1.4;
    font-size: 1.25rem;
    margin-bottom: 3rem;
  }

  button {
    width: 100%;
    margin-bottom: 1rem;
  }
}
