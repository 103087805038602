@import "../../scss/main";

.advise {
  .advise_container {
    width: 980px;
    max-width: 90vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 4rem;
    padding-top: 2rem;
    text-align: center;
  }

  h1 {
    font-size: 2.5rem;
    color: $SkyBlue;
    line-height: 1.2;
    margin-bottom: 1.5rem;

    @include md {
      font-size: 1.5rem;
    }
  }

  h2 {
    font-size: 2rem;
    color: $SkyBlue;
    width: 85%;
    margin: 0 auto;
    margin-bottom: 2rem;

    @include md {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      width: 100%;
    }
  }

  .advise_images {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;

    @include md {
      justify-content: center;
    }

    img {
      width: 30%;
      height: fit-content;

      @include md {
        width: 40%;
      }
      @include sm {
        width: 90%;
      }
    }
  }

  .advise_downIcon {
    height: 2.5rem;
    cursor: pointer;

    margin-top: 2rem;
  }

  h4 {
    font-size: 1.3rem;
    color: $PurpleColor;
    margin-bottom: 1rem;
    line-height: 1.2;
    margin-top: 2rem;

    @include md {
      font-size: 1.125rem;
    }
  }

  p {
    margin-bottom: 2rem;

    @include md {
      font-size: 0.9rem;
    }
  }

  .advise_logos {
    display: flex;
    gap: 4rem;
    margin-top: 1rem;
    margin-bottom: 4rem;

    img {
      height: 3.25rem;
      width: 10rem;
      object-fit: cover;
      cursor: pointer;

      @include xs {
        height: 2rem;
        width: 6rem;
      }
    }
  }

  h3 {
    font-size: 2rem;
    color: $PurpleColor;
    line-height: 1.5;
    margin-bottom: 2rem;
    margin-top: 10vh;

    @include md {
      font-size: 1.25rem;
    }
  }

  .advise_optionsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    margin-bottom: 3rem;

    @include sm {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }

  .advise_options {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    width: 100%;
    padding-left: 20%;

    @include sm {
      padding-left: 0;
    }

    span {
      @include md {
        font-size: 0.9rem;
      }
    }
  }

  .advise_footer {
    padding: 3rem 0;
    background-color: $PurpleColor;
    width: 100vw;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    img {
      height: 10rem;

      @include md {
        height: 7rem;
      }
    }
  }

  .advise_footerMail {
    color: $BlackColor;
    text-decoration: none;
    cursor: pointer;
    font-family: "Helvetica";
    text-align: center;
    padding-bottom: 4rem;
    margin: 0 auto;
    width: fit-content;
    display: inherit;
  }

  .advisor_form {
    width: 100%;

    .advisor_formInputs {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      margin-bottom: 1rem;

      @include sm {
        grid-template-columns: 1fr;
      }
    }
  }

  .advisorForm_categoriesContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;

    h4 {
      font-family: "Helvetica";
      margin-bottom: 1rem;
      color: $BlackLight;
      margin-top: 1rem;
      line-height: 1;
    }

    .advisorForm_parentCategory {
      display: flex;
      gap: 1rem;
      align-items: center;
      font-family: "Helvetica";
      margin-bottom: 0.5rem;
      cursor: pointer;

      input[type="checkbox"] {
        height: 1rem;
        width: 1rem;
      }
    }

    .advisorForm_childCategory {
      @extend .advisorForm_parentCategory;
      margin-left: 2rem;
      &:has(+ .advisorForm_parentCategory) {
        margin-bottom: 2rem;
      }
    }
  }

  .advisorForm_passwordInput {
    position: relative;

    img {
      position: absolute;
      top: 36px;
      right: 1rem;
      height: 1rem;
      cursor: pointer;
    }
  }

  .advisorForm_categoriesContainer {
    margin-bottom: 1rem;
  }

  .advisor_privacyCheckbox {
    display: flex;
    font-family: "Helvetica";
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.5rem;

    label {
      cursor: pointer;
      text-align: left;
    }

    & > div {
      display: flex;
      align-items: center;
    }

    input {
      min-height: 1.25rem;
      min-width: 1.25rem;
      margin-right: 1rem;
      cursor: pointer;

    }

  }

  .PhoneInput {
    width: 100%;
  }

  .advisor_phoneCheckbox {
    @extend .advisor_privacyCheckbox;
    margin-bottom: 0;

    label {
      margin-bottom: 0;
    }

    input {
      width: auto;
      box-shadow: none;
      height: auto;
      margin-right: 12px;
      min-height: 1rem;
      min-width: 1rem;
    }
  }
}
