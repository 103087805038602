@import "../../scss/main";

.home {
  .home_container {
    width: 980px;
    max-width: 90vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 0;
  }

  h1 {
    font-size: 2.75rem;
    font-weight: 700;
    font-family: "Helvetica";
    text-align: center;
    margin-bottom: 1rem;
    line-height: 1.2;

    @include md {
      font-size: 2rem;
    }
  }

  h4 {
    font-size: 1.25rem;
    font-family: "Helvetica";
    font-weight: 400;
    margin-bottom: 2rem;
    line-height: 1.2;

    @include md {
      font-size: 1rem;
    }
  }

  .home_images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include md {
      gap: 1rem;
    }

    img {
      width: 32%;
      height: fit-content;

      @include md {
        width: 40%;
      }
      @include sm {
        width: 100%;
      }
    }
  }

  .home_logos {
    display: flex;
    gap: 10vw;
    align-content: flex-start;
    margin-top: 20vh;

    @include sm {
      justify-content: space-around;
      gap: 2rem;
    }

    div,
    img {
      cursor: pointer;
    }

    p {
      font-family: "Helvetica";
      text-align: center;
      margin-top: 0;
      line-height: 1;
    }

    img {
      height: 3.25rem;
      width: 10rem;
      object-fit: cover;

      @include xs {
        height: 2rem;
        width: 6rem;
      }
    }
  }

  .home_footer {
    margin-top: 20vh;
    color: $BlackColor;
    text-decoration: none;
    cursor: pointer;
    font-family: "Helvetica";
  }
}
