@import "../../scss/main";

.navbar {
  background: $PrimaryColor;
  backdrop-filter: blur(10px);
  height: 90px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;

  .container {
    width: 980px;
    max-width: 90vw;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navbar_logo {
    height: 4.6rem;
    cursor: pointer;

    @include md {
      margin-left: 50%;
      transform: translateX(-50%);
      height: 3.5rem;

    }

    @include xs {
      margin-left: 0rem;
      transform: translateX(0);
      height: 3.5rem;

    }
  }

  .navbar_items {
    display: flex;
    gap: 2rem;
    align-items: center;

    @include lg {
      gap: 1rem;
    }

    @include md {
      display: none;
    }
  }

  .navItem_active {
    color: $WhiteColor !important;
    text-decoration: underline;
    font-weight: bold;
    span {
      color: $WhiteColor !important;

      @include md {
        color: $BlackColor !important;
        text-decoration: underline;
      }
    }
  }

  .navItem {
    position: relative;
    display: flex;
    gap: 0.3rem;
    cursor: pointer;
    color: $WhiteColor;

    @include md {
      flex-direction: column;
    }

    &:hover {
      span, a {
        text-decoration: underline;
      }
    }

    span, a {
      font-size: 12px;
      color: $WhiteColor;
      text-decoration: none;

      @include md {
        color: $BlackColor;
      }
    }

    &:hover .navItem_menu {
      display: flex;
    }

    .navItem_menu {
      position: absolute;
      bottom: 0;
      left: 50%;
      background-color: $WhiteColor;
      box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
      padding: 0.5rem 2rem;
      transform: translate(-50%, 100%);
      border-radius: 0.3rem;
      cursor: default;
      display: none;
      flex-direction: column;
      transition: all 0.2s;
      align-items: center;

      @include md {
        position: relative;
        box-shadow: none;
        display: block;
        transform: none;
        bottom: auto;
        left: auto;
      }

      div {
        white-space: nowrap;
        cursor: pointer;
        font-size: 1.125rem;
        color: $BlackColor;

        &:hover {
          color: $PrimaryColor;
        }
      }
    }
  }

  .navbar_mobileMenu {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    width: 100%;
    transition: all 0.3s ease-in-out;
    background-color: $WhiteColor;
    padding-bottom: 1rem;
    z-index: 5;
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.5);
    padding-top: 1rem;

    @include md {
      display: block;

      .navbar_items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 1.5rem;
      }

      .navbar_login {
        display: flex;
        margin-top: 1rem;
        padding-left: 1.5rem;
      }
    }
  }

  .hamburger {
    display: none;

    @include md {
      display: block;
    }
  }

  .hamburger .line {
    width: 50px;
    height: 5px;
    background-color: $WhiteColor;
    display: block;
    margin: 8px auto;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    border-radius: 0.5rem;
  }

  .hamburger:hover {
    cursor: pointer;
  }

  .hamburger.is-active .line:nth-child(2) {
    opacity: 0;
  }

  .hamburger.is-active .line:nth-child(1) {
    -webkit-transform: translateY(13px) rotate(45deg);
    -ms-transform: translateY(13px) rotate(45deg);
    -o-transform: translateY(13px) rotate(45deg);
    transform: translateY(13px) rotate(45deg);
  }

  .hamburger.is-active .line:nth-child(3) {
    -webkit-transform: translateY(-13px) rotate(-45deg);
    -ms-transform: translateY(-13px) rotate(-45deg);
    -o-transform: translateY(-13px) rotate(-45deg);
    transform: translateY(-13px) rotate(-45deg);
  }
}
