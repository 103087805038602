@import "../../scss/main";

.otpModal {
  background-color: $WhiteColor;
  width: 400px;
  height: 300px;
  max-width: calc(100vw - 20px);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  padding-top: 3rem;
  gap: 1rem;

  h3 {
    font-family: "Helvetica";
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  input {
    height: 3rem;
    width: 3rem !important;
    margin: 0 0.5rem;
    outline: none;
  }

  button {
    margin-top: 1.5rem;
  }
}

.otpModal_error {
  input {
    border:2px solid $ErrorColor;
    border-radius: 3px;
  }
}
